import { lazy, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';

import { useAppContext } from '../../context/AppContext';
import { useAuth } from '../../context/AuthContext';
import { useCountry } from '../../context/CountryContext';
import useCurrency from '../../hooks/useCurrency';
import { useUrl } from '../../hooks/useUrl';
import { omsAuthenticated } from '../../utils/apiOms';
import { DEFAULT_CURRENCYCODE, DEFAULT_SITECODE } from '../../utils/common';
import Loader from '../Loader/Loader';
import LoaderWrapper from '../Loader/LoaderWrapper';

const Swiper = lazy(() => import('./Swiper'));

export const FETCH_WISHLIST_KEY = 'wishlist';

const Wishlist = () => {
    const { authenticated } = useAuth();
    const { brand, site } = useAppContext();
    const currency = useCurrency();
    const { country } = useCountry();
    const { urls } = useUrl();

    const { data, isLoading } = useQuery(
        'wishlist',
        () =>
            omsAuthenticated.getWishlistItemsAuthenticated({
                brandCode: brand,
                siteCode: site.code || DEFAULT_SITECODE,
                countryCode: country.countryISO,
                currencyCode: currency?.code?.toLowerCase() || DEFAULT_CURRENCYCODE,
                itemsPerPage: 6,
                page: 1,
                lastOnesAvailable: true,
            }),
        {
            enabled: authenticated,
        }
    );

    const products = data?.data ? data.data.filter(product => product?.link) : [];
    if (products.length === 0) {
        return null;
    }

    return (
        <div className="o-container">
            <div className="o-grid o-grid--gutter-sm-bis@sm u-mt-md u-mt-xl@md-plus">
                <div className="o-grid__col o-grid__col--22 o-grid__col--offset-1">
                    <div className="o-grid o-grid--gutter-sm-bis u-flex-cross-baseline">
                        <div className="o-grid__col">
                            <h2 className="u-text-xxl-plus u-text-uppercase">
                                <FormattedMessage id="wishlist.title" />
                            </h2>
                        </div>
                        <div className="o-grid__col u-none@sm">
                            <a href={urls.wishlist} className="c-link c-link--senary">
                                <FormattedMessage id="wishlist.view_all" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="o-grid__col o-grid__col--24 o-grid__col--22@md o-grid__col--22@md-plus o-grid__col--offset-1@md o-grid__col--offset-1@md-plus">
                    <div className="swiper">
                        <LoaderWrapper isLoading={isLoading}>
                            <Suspense fallback={<Loader />}>
                                <Swiper items={products} />
                            </Suspense>
                        </LoaderWrapper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Wishlist;
