import React, { ReactNode, useContext } from 'react';
import { useQuery } from 'react-query';

import { omsAuthenticated } from '../utils/apiOms';
import { useAuth } from './AuthContext';
import { OMS } from '../types';
import { useAppContext } from './AppContext';

type UserStateContextType = {
    userState?: OMS.UserStateAuthenticated;
};

const UserStateContext = React.createContext<UserStateContextType>({});
const USERSTATE_KEY = 'user-state';

export const UserStateProvider = ({ children }: { children: ReactNode }) => {
    const { brand } = useAppContext();
    const { authenticated } = useAuth();

    const { data } = useQuery(
        USERSTATE_KEY,
        () =>
            omsAuthenticated.getUserStateAuthenticated({
                brandCode: brand,
            }),
        {
            enabled: authenticated,
        }
    );

    return <UserStateContext.Provider value={{ userState: data?.data }}>{children}</UserStateContext.Provider>;
};

export const useUserState = () => {
    const userStateContext = useContext(UserStateContext);
    return userStateContext;
};
