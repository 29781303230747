const Minus = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="7"
        viewBox="0 0 7 7"
        className="c-icon__svg"
        aria-hidden="true"
    >
        <path d="M1 3V4H6V3H1Z"/>
    </svg>
);

export default Minus;
