import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { useAppContext } from '../../context/AppContext';
import { useOrderContext } from '../../context/OrderContext';
import apiClient from '../../utils/api';
import { filterPromosInOrder } from '../../utils/promos';
import Promos, { FETCH_PROMOS_KEY } from '../Promos/Promos';
import Modal from './Modal';

export const FETCH_PROMO_QUERY_PARAMS = 'fetchPromo';

const PromosModal = () => {
    const location = useLocation();
    const history = useHistory();
    const { orderId, site, brand } = useAppContext();
    const { order } = useOrderContext();
    const query = new URLSearchParams(location.search);
    const fetchPromo = query.has(FETCH_PROMO_QUERY_PARAMS);
    const [isOpen, setIsOpen] = useState(false);
    const [isFetchPromo, setIsFetchPromo] = useState(query.has(FETCH_PROMO_QUERY_PARAMS));

    useEffect(() => {
        if (isFetchPromo) {
            query.delete(FETCH_PROMO_QUERY_PARAMS);
            history.replace({
                search: query.toString(),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchPromo]);

    const onRequestClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const { data: promos } = useQuery(
        FETCH_PROMOS_KEY,
        () =>
            apiClient.getPromotionalCampaign(orderId!, {
                site: site.code!,
                brand,
            }),
        {
            enabled: !!orderId && order && fetchPromo,
        }
    );

    useEffect(() => {
        if (isFetchPromo && order && promos?.data && filterPromosInOrder(order, promos.data).length > 0) {
            setIsOpen(true);
            setIsFetchPromo(false);
        }
    }, [isFetchPromo, order, promos]);

    return (
        <Modal onRequestClose={onRequestClose} isOpen={isOpen} className="c-dialog--lg">
            <Promos onAdd={onRequestClose} />
        </Modal>
    );
};

export default PromosModal;
