const ClockIcon = () => (

    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        className="c-icon__svg"
        aria-hidden="true"
    >
        <path d="M7-.00006104c-3.86562 0-7 3.13437104-7 7.00000104 0 3.86566 3.13438 6.99996 7 6.99996 3.8656 0 7-3.1343 7-6.99996 0-3.86563-3.1344-7.00000104-7-7.00000104zM7 12.8124c-3.20937 0-5.8125-2.6031-5.8125-5.81246 0-3.20938 2.60313-5.8125 5.8125-5.8125 3.2094 0 5.8125 2.60312 5.8125 5.8125 0 3.20936-2.6031 5.81246-5.8125 5.81246z"/>
        <path d="M9.86107 8.5768L7.4847 7.11717V3.6132c0-.06229-.05999-.11326-.13331-.11326h-.80157c-.07332 0-.13332.05097-.13332.11326v3.89896c0 .03681.02.07079.055.09202l2.75632 1.70739c.05999.03681.14331.02549.18664-.02407l.4766-.55214c.04333-.05238.03-.12317-.02999-.15856z"/>
    </svg>
);

export default ClockIcon;
