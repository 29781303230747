const WritingIcon = () => (

    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="11"
        viewBox="0 0 11 11"
        className="c-icon__svg"
        aria-hidden="true"
    >
        <path d="M2 8l2.71458-.00826 5.92492-5.25081c.2326-.20806.3605-.48436.3605-.77827s-.1279-.57022-.3605-.77827L9.66393.311458c-.46504-.416103-1.2764-.413901-1.73775-.001651L2 5.56173V8zm6.79414-6.91028l.97744.87129-.98237.87073-.9756-.87238.98053-.86964zM3.23026 6.02076l3.70925-3.28754.9756.87293-3.70863 3.28644-.97622.00276v-.87459z"/>
        <path d="M1.11111 11h7.77778C9.50167 11 10 10.5017 10 9.88889V5.07333L8.88889 6.18444v3.70445H2.86556c-.01445 0-.02945.00555-.04389.00555-.01834 0-.03667-.005-.05556-.00555h-1.655V2.11111H4.915L6.02611 1h-4.915C.498333 1 0 1.49833 0 2.11111v7.77778C0 10.5017.498333 11 1.11111 11z"/>
    </svg>
);

export default WritingIcon;
