import { useEffect, useRef } from 'react';
import useAnalytics from '../../utils/analytics';
import { useUrl } from '../../hooks/useUrl';

const Footer = () => {
    const {urls } = useUrl();
    const { onNewsletterSubscription } = useAnalytics();
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        const listener = (e: MessageEvent) => {
            if (e.data.height && iframeRef.current) {
                iframeRef.current.style.height = e.data.height + 'px';
                iframeRef.current.height = e.data.height;
            }

            if (e.data.width && iframeRef.current) {
                iframeRef.current.width = e.data.width;
            }

            if (e.data.event === 'GAevent' && e.data.category === 'Newsletter_Subscription' && e.data.action) {
                onNewsletterSubscription(e.data.action);
            }

            if (e.data.type === 'openLink' && e.data.url) {
                window.open(e.data.url, '_blank')
            }
        };
        window?.addEventListener('message', listener);

        return () => {
            window.removeEventListener('message', listener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <iframe
            ref={iframeRef}
            src={`${urls.ecommerce}/footer`}
            title="footer"
            scrolling="no"
            style={{ width: '100%', height: '100%', visibility: 'hidden', border: 0, overflow: 'hidden' }}
            onLoad={() => {
                if (iframeRef.current) {
                    iframeRef.current.style.visibility = 'visible';
                }
            }}
        />
    );
};

export default Footer;
