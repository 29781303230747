import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';

import useCurrency from '../../hooks/useCurrency';
import { useAppContext } from '../../context/AppContext';
import useMutationWithError from '../../hooks/useMutationWithError';
import { omsAuthenticated } from '../../utils/apiOms';
import { FETCH_WISHLIST_KEY } from '../Wishlist/Wishlist';
import Button from './Button';

type Props = {
    children?: ReactNode;
    ean: string;
    className?: string;
    onAdd?: () => void;
};

const AddToWishlist = ({
    ean,
    className,
    onAdd,
    children = <FormattedMessage id="actions.add_to_wishlist" />,
}: Props) => {
    const { brand, orderId, site } = useAppContext();
    const queryClient = useQueryClient();
    const currency = useCurrency();

    const addProduct = useMutationWithError(
        () =>
            omsAuthenticated.addWishlistItemAuthenticated(ean, {
                brandCode: brand,
                siteCode: site.code!,
                currencyCode: currency?.code!,
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(FETCH_WISHLIST_KEY);
                onAdd && onAdd();
            },
        }
    );

    if (!orderId) {
        return null;
    }

    return (
        <Button
            type="button"
            className={className ? className : 'c-btn c-btn--close c-btn--loader'}
            onClick={() => addProduct.mutate()}
            isLoading={addProduct.isLoading}
        >
            {children}
        </Button>
    );
};

export default AddToWishlist;
