import { useEffect } from 'react';

export const useFocus = (onFocus: () => void) => {
    useEffect(() => {
        window.addEventListener('visibilitychange', onFocus, false);
        window.addEventListener('focus', onFocus, false);

        return () => {
            // Be sure to unsubscribe if a new handler is set
            window.removeEventListener('visibilitychange', onFocus);
            window.removeEventListener('focus', onFocus);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
