import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';

import { useAppContext } from '../../context/AppContext';
import { FETCH_ORDER_KEY } from '../../context/OrderContext';
import useMutationWithError from '../../hooks/useMutationWithError';
import apiClient from '../../utils/api';
import Button from './Button';
import useCurrency from '../../hooks/useCurrency';

type Props = {
    campaignId?: number;
    className?: string;
    ean: string | string[];
    label?: ReactNode;
    onAdd?: () => void;
};

const AddToBasket = ({ campaignId, className, ean, label, onAdd }: Props) => {
    const queryClient = useQueryClient();
    const { brand, orderId, site } = useAppContext();
    const currency = useCurrency();

    const eans = Array.isArray(ean) ? ean : [ean];

    const addProduct = useMutationWithError(
        () =>
            apiClient.addProductOnOrder(
                orderId,
                {
                    site: site.code!,
                    brand,
                    currency: currency?.code,
                    last_minute: false,
                    campaignId,
                },
                { eans }
            ),
        {
            onSuccess: data => {
                if (data) {
                    queryClient.setQueryData(FETCH_ORDER_KEY, data);
                    onAdd && onAdd();
                }
            },
        }
    );

    if (!orderId) {
        return null;
    }

    return (
        <Button
            type="button"
            className={className ? className : 'c-btn c-btn--close c-btn--loader u-w-100'}
            onClick={() => addProduct.mutate()}
            isLoading={addProduct.isLoading}
        >
            {label ? label : <FormattedMessage id="actions.add_to_basket" />}
        </Button>
    );
};

export default AddToBasket;
