import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';
import ErrorFallback from '../Errors/ErrorFallback';
import { ClockIcon } from '../Icons';

export const formatTimeRemaining = (ms: number) => {
    if (ms <= 0) {
        return '00:00';
    }

    const min = Math.floor(ms / 60000);
    const sec = ((ms % 60000) / 1000).toFixed(0);

    return `${min.toString().padStart(2, '0')}:${sec.padStart(2, '0')}`;
};

interface Props {
    className?: string;
    expireAt: string;
}

const Timer = ({ className, expireAt }: Props) => {
    const [timeRemaining, setTimeRemaining] = useState(new Date(expireAt).getTime() - Date.now());

    useEffect(() => {
        const timer = setInterval(() => {
            const msRemaining = new Date(expireAt).getTime() - Date.now();
            setTimeRemaining(msRemaining);
            if (msRemaining < 0) {
                // TODO: handle "end of timer" action
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expireAt]);

    return (
        <p className={classnames('c-timer', className)}>
            <i className="c-icon u-text-icon-xs u-mr-xs-sm">
                <ClockIcon />
            </i>
            <FormattedMessage
                id="cart.timer.label"
                values={{
                    time: (
                        <span className="c-timer__value">
                            {formatTimeRemaining(timeRemaining)} <FormattedMessage id="cart.timer.minutes" />
                        </span>
                    ),
                }}
            />
        </p>
    );
};

export default withErrorBoundary(Timer, { FallbackComponent: ErrorFallback });
