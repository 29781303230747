import { Api, ApiConfig } from '../types';
import { getAuth } from './auth';
import { setCartUpdated } from './order';

export const getApiConfig = <T>(baseUrl = '', apiKey = ''): T =>
    ({
        baseUrl,
        baseApiParams: {
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': apiKey,
            },
            redirect: 'follow',

            referrerPolicy: 'no-referrer',
            secure: true,
        },
        securityWorker: async () => {
            const auth = await getAuth(true);

            if (!auth?.accessToken) return {};

            return {
                headers: {
                    Authorization: `Bearer ${auth.accessToken}`,
                },
            };
        },
    } as T);

type Target<T> = T & { [key: string]: Function };

export const proxyHandler: ProxyHandler<any> = {
    get<T extends ApiMethods>(target: Target<T>, propKey: keyof T) {
        const origMethod = target[propKey];
        return function (this: void, ...args: any[]) {
            let result = origMethod.apply(this, args);
            return result
                .catch((e: Response) => {
                    if (e.status === 401) {
                        return getAuth().then(() => origMethod.apply(this, args));
                    }

                    throw e;
                })
                .then((response: Response) => {
                    if (['addProductOnOrder', 'updateProductOnOrder'].includes(propKey as string)) {
                        setCartUpdated();
                    }

                    return response;
                });
        };
    },
};

const apiClient = new Api(getApiConfig<ApiConfig>(process.env.REACT_APP_API_URL, process.env.REACT_APP_API_KEY));

type ApiMethods = typeof apiClient.ecommercePublic;

const createApiClientProxy = (client: Api<unknown>): ApiMethods => {
    return new Proxy(client.ecommercePublic, proxyHandler);
};

export default createApiClientProxy(apiClient);
