import { Redirect, Route, RouteProps } from 'react-router';
import { useWorkflow, getWorkflowLinks, STEP } from '../context/WorkflowContext';
import { useUrl } from '../hooks/useUrl';

interface WorkflowRouteProps extends RouteProps {
    step: STEP;
}

const WorkflowRoute = ({ children, step, ...rest }: WorkflowRouteProps) => {
    const { maxStep } = useWorkflow();
    const { routes } = useUrl();

    const workflowLinks = getWorkflowLinks(routes);
    const shouldRedirect = step > maxStep && workflowLinks[maxStep];

    return <Route {...rest}>{shouldRedirect ? <Redirect to={workflowLinks[maxStep]!.to} /> : children}</Route>;
};

export default WorkflowRoute;
