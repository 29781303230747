import { ReactNode } from 'react';
import Loader, { LoaderProps } from './Loader';

interface LoaderWrapperProps {
    children?: ReactNode;
    isLoading: boolean;
    loaderProps?: LoaderProps;
}

const LoaderWrapper = ({ children, isLoading, loaderProps = {} }: LoaderWrapperProps) => {
    return isLoading ? <Loader {...loaderProps} /> : <>{children}</>;
};

export default LoaderWrapper;
