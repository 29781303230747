import jwtDecode from 'jwt-decode';
import { getSiteCodeFromUrl } from '../hooks/useUrl';
import { getFrontUrl } from './brand';

let TOKEN: string | null = null;
let IS_REFRESHING_TOKEN: boolean = false;
interface TokenResponse {
    refresh_expires_in: number;
    token_type: string;
    id_token: string;
    'not-before-policy': number;
    session_state: string;
    scope: string;
    access_token: string;
    refresh_token: string;
    expires: number;
}

export interface KeyCloakJwtToken {
    exp: number;
    iat: number;
    auth_time: number;
    jti: string;
    iss: string;
    aud: string;
    sub: string;
    typ: string;
    azp: string;
    session_state: string;
    acr: string;
    'allowed-origins': string[];
    realm_access: RealmAccess;
    resource_access: ResourceAccess;
    scope: string;
    birthdate: string;
    email_verified: boolean;
    gender: string;
    name: string;
    phone_number: string;
    preferred_username: string;
    given_name: string;
    family_name: string;
    sezaneId: string;
    email: string;
}

interface RealmAccess {
    roles: string[];
}

interface ResourceAccess {
    account: Account;
}

interface Account {
    roles: string[];
}

export interface AuthData {
    accessToken: string;
    parsedToken: KeyCloakJwtToken;
}

const refreshAccessToken = async (silentCheck: boolean) => {
    // Check if already refreshing since we can have parallel calls
    if (IS_REFRESHING_TOKEN) return TOKEN ? ({ accessToken: TOKEN, parsedToken: jwtDecode(TOKEN) } as AuthData) : null;

    IS_REFRESHING_TOKEN = true;

    const tokens = await fetch(`${getFrontUrl()}/${getSiteCodeFromUrl()}/security/token`, {
        credentials: 'include',
    }).then(response => {
        if (response.status === 401 && !silentCheck) {
            redirectToKeycloak();
        }

        return response.json() as Promise<TokenResponse>;
    });

    IS_REFRESHING_TOKEN = false;

    if (tokens && tokens.access_token) {
        TOKEN = tokens.access_token;
        return { accessToken: tokens.access_token, parsedToken: jwtDecode(tokens.access_token) } as AuthData;
    }

    return null;
};

export const redirectToKeycloak = (redirectUrl?: string) => {
    const queryParams = new URLSearchParams();
    queryParams.append('redirect', redirectUrl || window.location.href);

    window.location.href = `${getFrontUrl()}/${getSiteCodeFromUrl()}/connect?${queryParams.toString()}`;
};

export const getAccessToken = () => TOKEN;

export const getAuth = async (silentCheck = false) => {
    let accessToken = getAccessToken();

    // We check access token expiration and handle refresh if needed
    if (accessToken) {
        const parsedJwt = jwtDecode<KeyCloakJwtToken>(accessToken);
        // Check expiration with a one minute margin
        if (parsedJwt.exp - 60 < Math.round(new Date().getTime() / 1000)) {
            return refreshAccessToken(silentCheck);
        }

        return { accessToken, parsedToken: parsedJwt };
    }
    return refreshAccessToken(silentCheck);
};
