import { shouldPolyfill } from '@formatjs/intl-displaynames/should-polyfill';

async function polyfill(locale: string) {
    const unsupportedLocale = shouldPolyfill(locale);
    if (!unsupportedLocale) {
        return;
    }

    Promise.all([
        await import('@formatjs/intl-locale/polyfill'),
        await import('@formatjs/intl-displaynames/polyfill'),
        ['de', 'es', 'fr', 'it', 'pt', 'ja', 'ko'].includes(locale)
            ? (locale === 'de' ? import('@formatjs/intl-displaynames/locale-data/de') : Promise.resolve(null),
              locale === 'es' ? import('@formatjs/intl-displaynames/locale-data/es') : Promise.resolve(null),
              locale === 'fr' ? import('@formatjs/intl-displaynames/locale-data/fr') : Promise.resolve(null),
              locale === 'it' ? import('@formatjs/intl-displaynames/locale-data/it') : Promise.resolve(null),
              locale === 'pt' ? import('@formatjs/intl-displaynames/locale-data/pt') : Promise.resolve(null),
              locale === 'ja' ? import('@formatjs/intl-displaynames/locale-data/ja') : Promise.resolve(null),
              locale === 'ko' ? import('@formatjs/intl-displaynames/locale-data/ko') : Promise.resolve(null))
            : import('@formatjs/intl-displaynames/locale-data/en'),
    ]);
}

export default polyfill;
