import classnames from 'classnames';

export interface LoaderProps {
    className?: string;
    color?: string;
    fullPage?: boolean;
    fullScreen?: boolean;
    large?: boolean;
    shadow?: boolean;
    size?: number;
}

const Loader = ({ className, fullPage, fullScreen, large, shadow, color, size }: LoaderProps) => (
    <div
        className={classnames('c-loader', className, {
            'c-loader--fullpage': fullPage,
            'c-loader--fullscreen': fullScreen,
            'c-loader--large': large,
            'c-loader--shadow': shadow,
        })}
    >
        <div className="c-loader__spinner" style={{ borderTopColor: color, height: size, width: size }} />
    </div>
);

export default Loader;
