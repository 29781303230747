import React, { ReactNode, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { useUrl } from '../hooks/useUrl';
import scrollTo from '../utils/scrollTo';
import Modal from './../components/Modal/Modal';

type Error = {
    code?: string;
    message?: ReactNode;
    onCloseModal?: () => void;
};

type ErrorContextType = {
    error?: Error;
    setError: (error: Error) => void;
};

enum ErrorsCodes {
    PAYMENT_ERROR_LMS_DELIVERY_EMPTY = 'PAYMENT_ERROR_LMS_DELIVERY_EMPTY',
}

const ErrorContext = React.createContext<ErrorContextType>({
    error: undefined,
    setError: () => {},
});

export const ErrorProvider = ({ children }: { children: ReactNode }) => {
    const history = useHistory();
    const { routes } = useUrl();
    const [error, setError] = useState<Error>();

    // Handle some specific error cases here
    const onRequestClose = () => {
        if (error?.code === ErrorsCodes.PAYMENT_ERROR_LMS_DELIVERY_EMPTY) {
            history.push(routes.shipping, { step: 1 });
            scrollTo('#shipping');
        }

        if (error?.onCloseModal) {
            error.onCloseModal();
        }

        setError(undefined);
    };

    return (
        <ErrorContext.Provider value={{ error, setError }}>
            {children}
            {error !== undefined && (
                <Modal isOpen={true} onRequestClose={onRequestClose}>
                    <p>
                        <FormattedMessage id="error.server" />
                    </p>
                    {error && <p className="u-mt-sm">{typeof error === 'string' ? error : error.message}</p>}
                </Modal>
            )}
        </ErrorContext.Provider>
    );
};

export const useErrorContext = () => {
    return useContext(ErrorContext);
};
