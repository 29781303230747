import { useEffect } from 'react';
import { useLocation } from 'react-router';

const HistoryListener = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return null;
};

export default HistoryListener;
