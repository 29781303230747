import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Routes } from '../hooks/useUrl';
import { Order } from '../types/api';
import { redirectResult } from '../utils/order';
import { useAuth } from './AuthContext';
import { useOrderContext } from './OrderContext';

export enum STEP {
    CART = 1,
    SHIPPING,
    PAYMENT,
}

type WorkflowContextType = {
    maxStep: STEP;
    nextStep: () => void;
};

const WorkflowContext = React.createContext<WorkflowContextType>({
    maxStep: STEP.CART,
    nextStep: () => {},
});

export const getWorkflowLinks = (routes: Routes, isGlobalE = false) => {
    if (isGlobalE) {
        return {
            [STEP.CART]: {
                to: routes.cart,
                label: 'breadcrumbs.cart',
            },
            [STEP.SHIPPING]: {
                to: routes.globalE,
                label: 'breadcrumbs.shipping',
                reloadDocument: true,
            },
        };
    }

    return {
        [STEP.CART]: {
            to: routes.cart,
            label: 'breadcrumbs.cart',
        },
        [STEP.SHIPPING]: {
            to: routes.shipping,
            label: 'breadcrumbs.shipping',
        },
        [STEP.PAYMENT]: {
            to: routes.payment,
            label: 'breadcrumbs.payment',
        },
    };
};

const canAccessPayment = (order: Order) =>
    order.addresses?.shipping ||
    order.addresses?.lastMinuteShipping ||
    // Use need address only in specific case (ecards)
    (!order.needAddress && order.products?.length === order.products?.filter(product => product.ecardProperty).length);

export const WorkflowProvider = ({ children }: { children: ReactNode }) => {
    const [maxStep, setMaxStep] = useState(STEP.PAYMENT);
    const { order, isLoadingOrder } = useOrderContext();
    const { isAuthenticating, authenticated } = useAuth();
    const nextStep = () => setMaxStep(maxStep + 1);

    useEffect(() => {
        if (order) {
            if (order.products && order.products?.length > 0) {
                if (canAccessPayment(order)) {
                    setMaxStep(STEP.PAYMENT);
                } else if (authenticated) {
                    setMaxStep(STEP.SHIPPING);
                }
            } else if (maxStep >= STEP.SHIPPING) {
                setMaxStep(STEP.CART);
            }
        } else if (!isLoadingOrder && !order && !isAuthenticating && !redirectResult) {
            setMaxStep(STEP.CART);
        }
    }, [order, isLoadingOrder, maxStep, isAuthenticating, authenticated]);

    return <WorkflowContext.Provider value={{ maxStep, nextStep }}>{children}</WorkflowContext.Provider>;
};

export const useWorkflow = () => {
    const workflowContext = useContext(WorkflowContext);
    return workflowContext;
};
