import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { useAuth } from '../../context/AuthContext';
import { useUrl } from '../../hooks/useUrl';
import { BRANDS } from '../../utils/brand';
import { ChevronIcon } from '../Icons';
import { getPaymentData } from '../../utils/order';

const Header = ({ brand }: Props) => {
    const { urls, routes } = useUrl();
    const { authenticated } = useAuth();
    const { pathname } = useLocation();

    const paymentData = getPaymentData();
    const DISPLAY_DONATION = paymentData?.donationToken;

    const logoDomain = process.env.NODE_ENV === 'development' ? 'https://localhost:3001' : '';
    const isWhiteLogo = pathname === routes.paymentSuccess && !DISPLAY_DONATION;
    const logoSrc = `${logoDomain}/${brand}${isWhiteLogo ? '_white' : ''}.svg`;

    return (
        <header className="c-header c-page__header">
            <div className="o-container">
                <div className="o-grid o-grid--nowrap">
                    <div className="o-grid__col o-grid__col--offset-1 o-grid__col--22">
                        <div className="c-header__inner">
                            <div className="u-flex u-flex-item-fluid">
                                <nav aria-label="Main navigation" className="c-nav c-nav--secondary c-nav--checkout">
                                    <ul role="menubar" className="c-nav__list u-flex">
                                        <li role="menuitem" className="c-nav__item u-flex u-flex-cross-center">
                                            <a
                                                href={urls.ecommerce}
                                                className="c-nav__link c-link c-link--tertiary c-link--start u-flex u-flex-cross-center"
                                            >
                                                <i className="c-icon c-icon--rotate-90 u-text-icon-xxs-bis@sm u-text-icon-xxxs u-mr-xs">
                                                    <ChevronIcon />
                                                </i>
                                                <span className="u-none@sm">
                                                    <FormattedMessage id="header.continue_shopping" />
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>

                            <div className="u-flex u-flex-main-center">
                                <span className="c-logo c-logo--baseline c-header__logo">
                                    <a href={urls.ecommerce} className="c-logo__link">
                                        <img src={logoSrc} alt={brand} className="c-logo__svg" />
                                    </a>
                                </span>
                            </div>

                            <div className="u-flex u-flex-item-fluid u-flex-main-end">
                                <div id="main-navigation" className="c-page__nav u-flex@md u-flex@sm">
                                    <div className="o-grid o-grid--nowrap o-grid--gutter-sm">
                                        <div className="o-grid__col u-flex-item-none">
                                            <nav
                                                aria-label="Secondary navigation"
                                                className="c-nav c-nav--secondary c-nav--checkout"
                                            >
                                                <ul role="menubar" className="c-nav__list u-flex">
                                                    <li
                                                        role="menuitem"
                                                        className="c-nav__item u-flex@md u-flex@md-plus u-none@sm"
                                                    >
                                                        <a
                                                            href={urls.account}
                                                            className="c-nav__link c-link c-link--tertiary c-link--start"
                                                        >
                                                            {authenticated ? (
                                                                <FormattedMessage id="header.account" />
                                                            ) : (
                                                                <FormattedMessage id="header.login" />
                                                            )}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

interface Props {
    brand: BRANDS;
}

export default Header;
