import classNames from 'classnames';
import { useQuery } from 'react-query';
import { useAppContext } from '../../context/AppContext';
import { useOrderContext } from '../../context/OrderContext';
import apiClient from '../../utils/api';
import { filterPromosInOrder } from '../../utils/promos';
import LoaderWrapper from '../Loader/LoaderWrapper';
import Promo from './Promo';
import { useIntl } from 'react-intl';

export const FETCH_PROMOS_KEY = 'promotionalCampaigns';

type Props = {
    className?: string;
    onAdd?: () => void;
};

const Promos = ({ className, onAdd }: Props) => {
    const { orderId, site, brand } = useAppContext();
    const { order } = useOrderContext();
    const intl = useIntl();

    const {
        data: promosResponse,
        isLoading,
        refetch,
    } = useQuery(
        FETCH_PROMOS_KEY,
        () =>
            apiClient.getPromotionalCampaign(orderId!, {
                site: site.code!,
                brand,
                locale: intl.locale,
            }),
        {
            enabled: !!orderId,
        }
    );

    const margin = 'u-mb-sm-bis u-mb-md@md u-mb-md@md-plus';

    return order && (isLoading || !!promosResponse?.data?.length) ? (
        <div className={classNames(className, margin)}>
            <LoaderWrapper isLoading={isLoading}>
                {promosResponse &&
                    order &&
                    filterPromosInOrder(order, promosResponse.data).map((promo, index, promos) => (
                        <Promo
                            key={promo.id}
                            className={index < promos.length - 1 ? margin : undefined}
                            promo={promo}
                            onAdd={() => {
                                refetch();
                                onAdd && onAdd();
                            }}
                        />
                    ))}
            </LoaderWrapper>
        </div>
    ) : null;
};

export default Promos;
