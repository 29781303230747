const Plus = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="7"
        viewBox="0 0 7 7"
        className="c-icon__svg"
        aria-hidden="true"
    >
        <path d="M3 1H4V3H6V4H4V6H3L3 4H1V3H3L3 1Z"/>
    </svg>
);

export default Plus;
