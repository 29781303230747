import { Order, PromotionalCampaign } from '../types/api';

export const filterPromosInOrder = (order: Order, promos: PromotionalCampaign[]): PromotionalCampaign[] =>
    promos.filter(promo =>
        order &&
        // Hide promo if one of the product is already added
        !order.products?.find(
            orderProduct =>
                promo.products &&
                promo.products.find(product => product.productId === orderProduct.productProperty?.productId)
        )
            ? promo
            : null
    );
