import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useAppContext } from '../../context/AppContext';
import { FETCH_ORDER_KEY } from '../../context/OrderContext';
import useMutationWithError from '../../hooks/useMutationWithError';
import { OrderVoucher } from '../../types/api';
import apiClient from '../../utils/api';
import { CrossIcon } from '../Icons';
interface TotalDiscountProps {
    discount: OrderVoucher;
    onRemove?: () => void;
    type: 'userCredit' | 'voucher' | 'ecards' | 'promo';
}

const TotalDiscount = ({ discount, onRemove, type }: TotalDiscountProps) => {
    const queryClient = useQueryClient();
    const { brand, orderId, site } = useAppContext();

    let method: typeof apiClient.removeCredit | typeof apiClient.removeVoucherOrEcardCode;
    let label = <FormattedMessage id="cart.total.credit" />;
    switch (type) {
        case 'ecards': {
            label = <FormattedMessage id="cart.total.credit.ecard" />;
            break;
        }
        case 'voucher': {
            method = apiClient.removeVoucherOrEcardCode;
            label = <FormattedMessage id="cart.total.credit.promo" />;
            break;
        }
        case 'userCredit': {
            method = apiClient.removeCredit;
            break;
        }
        case 'promo': {
            method = apiClient.deletePromotionalCampaignOnOrder;
            label = <FormattedMessage id="cart.total.credit.promo" />;
            break;
        }
    }

    const removeDiscount = useMutationWithError(
        (discountId: number) =>
            method(orderId, discountId, {
                site: site.code!,
                brand,
            }).then(data => {
                return data;
            }),
        {
            onSuccess: data => {
                if (data) {
                    queryClient.setQueryData(FETCH_ORDER_KEY, data);
                }
                onRemove && onRemove();
            },
        }
    );

    return (
        <li className="o-grid o-grid--gutter-sm u-flex-nowrap u-flex-main-between u-mt-xs-sm">
            <div className="o-grid__col u-flex u-flex-item-auto">
                <form className="u-flex-item-none">
                    <button
                        className="u-btn-unstyled u-line-height-null u-ml-n-sm"
                        onClick={() => removeDiscount.mutate(discount.id!)}
                        type="button"
                    >
                        <i className="c-icon u-text-icon-xxxs-bis u-mr-xs">
                            <CrossIcon />
                        </i>
                    </button>
                </form>
                <div>
                    {label}
                    <span className="c-total-checkout__text-additional u-ml-xxs-bis">"{discount.code}"</span>
                </div>
            </div>
            <div className="o-grid__col u-text-right">
                <span className="c-total-checkout__value">{discount.displayPrice}</span>
            </div>
        </li>
    );
};

export default TotalDiscount;
