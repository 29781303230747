import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useAppContext } from '../../context/AppContext';
import { FETCH_ORDER_KEY, useOrderContext } from '../../context/OrderContext';
import useMutationWithError from '../../hooks/useMutationWithError';
import { Order, OrderProduct } from '../../types/api';
import useAnalytics from '../../utils/analytics';
import apiClient from '../../utils/api';
import { FETCH_PROMOS_KEY } from '../Promos/Promos';

type Props = {
    product: OrderProduct;
    children?: ReactNode;
    className?: string;
    quantity?: number;
    id?: string;
    shouldUpdateProductQuantity?: (quantity: number) => boolean;
    onChange?: (order: Order) => void;
};

const ChangeQuantityFromBasket = ({
    product,
    className,
    quantity = 0,
    children = <FormattedMessage id="actions.remove_from_basket" />,
    shouldUpdateProductQuantity,
    onChange,
}: Props) => {
    const queryClient = useQueryClient();
    const { brand, orderId, site } = useAppContext();
    const { order } = useOrderContext();
    const { onRemoveProduct } = useAnalytics();
    const updateProductQuantity = useMutationWithError(
        () =>
            apiClient.updateProductOnOrder(
                orderId,
                product.orderItemProductId!,
                {
                    site: site.code!,
                    brand,
                },
                { quantity }
            ),
        {
            onSuccess: data => {
                queryClient.invalidateQueries(FETCH_PROMOS_KEY);

                if (quantity === 0) {
                    onRemoveProduct(order!, product);
                }

                queryClient.setQueryData(FETCH_ORDER_KEY, data);
                onChange && onChange(data.data);
            },
        }
    );

    if (!orderId) {
        return null;
    }

    return (
        <button
            className={className ? className : 'c-order-checkout__button'}
            type="button"
            onClick={() =>
                shouldUpdateProductQuantity
                    ? shouldUpdateProductQuantity(quantity) && updateProductQuantity.mutate()
                    : updateProductQuantity.mutate()
            }
            disabled={updateProductQuantity.isLoading}
        >
            {children}
        </button>
    );
};

export default ChangeQuantityFromBasket;
