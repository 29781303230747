function waitForElement(selector: string): Promise<Element | null> {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
    });
}

const scrollTo = async (selector: string) => {
    const element = await waitForElement(selector);
    element &&
        window.scrollTo({
            top: element.getBoundingClientRect().top,
            behavior: 'smooth',
        });
};

export default scrollTo;
