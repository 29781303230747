import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { MutationFunction, useMutation, UseMutationOptions } from 'react-query';
import { useErrorContext } from '../context/ErrorContext';
export interface MutationError {
    error: {
        message?: string;
    };
    status: number;
}

const useMutationWithError = <ResponseData, PayloadData = void>(
    func: MutationFunction<ResponseData, PayloadData>,
    config?: UseMutationOptions<ResponseData, MutationError, PayloadData>
) => {
    const mutation = useMutation(func, config);
    const { setError } = useErrorContext();
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (mutation?.error) {
            setError(mutation?.error?.error || mutation?.error);
        }
    }, [mutation.error, setError, formatMessage]);

    return mutation;
};

export default useMutationWithError;
