import { HTMLProps, ReactNode } from 'react';

type RadioProps = {
    checked: boolean;
    disabled?: boolean;
    id: string;
    label: ReactNode;
    name: string;
    onChange: () => void;
    labelProps?: Pick<HTMLProps<HTMLLabelElement>, 'style'>;
};

const Radio = ({ checked, disabled, id, label, name, onChange, labelProps = {} }: RadioProps) => (
    <>
        <input
            className="u-visually-hidden"
            id={id}
            name={name}
            type="radio"
            checked={checked}
            disabled={disabled}
            onChange={onChange}
        />
        <label
            htmlFor={id}
            className="c-label c-label--tertiary c-label--custom c-label--secondary-radio"
            {...labelProps}
        >
            {label}
        </label>
    </>
);

export default Radio;
