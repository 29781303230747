import classNames from 'classnames';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { useAppContext } from '../../context/AppContext';
import { FETCH_ORDER_KEY } from '../../context/OrderContext';
import { getRedirectUrl, useUrl } from '../../hooks/useUrl';
import useAnalytics from '../../utils/analytics';
import apiClient from '../../utils/api';
import { redirectToKeycloak } from '../../utils/auth';
import scrollTo from '../../utils/scrollTo';
import FieldErrorMessage from '../Form/FieldErrorMessage';
import { FETCH_PROMOS_KEY } from '../Promos/Promos';
import Button from '../Buttons/Button';

type FormValues = {
    voucher: string;
};

const errorMessages = defineMessages({
    voucherError: {
        id: 'validation.voucher.invalid',
    },
});

const VoucherForm = () => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const { onPromoCode } = useAnalytics();
    const history = useHistory();
    const query = new URLSearchParams(location.search);
    const code = query.get('code');
    const queryClient = useQueryClient();
    const { register, handleSubmit, reset, watch, setError, formState } = useForm<FormValues>();
    const { push } = useHistory();
    const { brand, orderId, site } = useAppContext();
    const { routes } = useUrl();

    const voucher = watch('voucher');

    const refetchPromos = async () => {
        push(routes.cart);
        await queryClient.invalidateQueries(FETCH_PROMOS_KEY);
        scrollTo('#order-promos');
    };

    useEffect(() => {
        if (code && code.length > 0) {
            query.delete('code');
            history.replace({
                search: query.toString(),
            });

            addCode.mutate(code);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code]);

    const addCode = useMutation(
        (code: string) =>
            apiClient
                .addCodeOnOrder(
                    orderId!,
                    {
                        site: site.code!,
                        brand,
                    },
                    { code }
                )
                .then(data => {
                    reset();
                    return data;
                }),
        {
            onSuccess: (data, voucherCode) => {
                onPromoCode(voucherCode, true);
                if (data) {
                    queryClient.setQueryData(FETCH_ORDER_KEY, { data: data.data.order });
                }

                if (data.data.type === 'campaign') {
                    refetchPromos();
                }
            },
            onError: (error: any, voucherCode) => {
                onPromoCode(voucherCode, false);
                // Some codes need to be authenticated, in these case we redirect to keycloak and then use the returned code to submit again after auth
                if (error?.status === 401) {
                    const params = new URLSearchParams();
                    params.append('code', voucherCode);
                    redirectToKeycloak(getRedirectUrl(site, routes.cart, params));
                } else {
                    setError('voucher', { message: errorMessages.voucherError.id });
                }
            },
        }
    );

    const onSubmit = (data: FormValues) => addCode.mutate(data.voucher);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={classNames('c-form-group', { 'is-invalid': formState.errors.voucher })}
        >
            <label htmlFor="cart-discount" className="u-visually-hidden">
                <FormattedMessage id="cart.voucher.label" />
            </label>
            <div className="u-flex">
                <input
                    {...register('voucher')}
                    type="text"
                    placeholder={formatMessage({ id: 'cart.voucher.label' })}
                    className="c-field u-flex-item-fluid"
                />
                <Button
                    isLoading={addCode.isLoading}
                    type="submit"
                    className="c-btn c-btn--secondary c-btn--square"
                    disabled={!voucher}
                >
                    <FormattedMessage id="cart.voucher.confirm" />
                </Button>
            </div>
            <FieldErrorMessage errors={formState.errors} name="voucher" />
        </form>
    );
};

export default VoucherForm;
