import classnames from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';
import { useWorkflow, getWorkflowLinks } from '../../context/WorkflowContext';
import { useUrl } from '../../hooks/useUrl';

defineMessages({
    cart: { id: 'breadcrumbs.cart' },
    shipping: { id: 'breadcrumbs.shipping' },
    payment: { id: 'breadcrumbs.payment' },
});

const Breadcrumbs = () => {
    const { maxStep } = useWorkflow();
    const { routes, urls } = useUrl();
    const location = useLocation();
    const { isGlobalE } = useAppContext();
    const workflowLinks = getWorkflowLinks(routes, isGlobalE);

    if (location.pathname.endsWith(routes.paymentSuccess)) {
        return null;
    }

    return (
        <div className="c-submenu c-submenu--fixed c-submenu--checkout">
            <div className="o-container">
                <div className="o-grid o-grid--nowrap">
                    <div className="o-grid__col o-grid__col--22 o-grid__col--offset-1">
                        <nav className="c-submenu__nav" aria-label="Checkout Menu">
                            <ul role="menubar" className="c-submenu__list">
                                {Object.values(workflowLinks).map((link, index) => {
                                    const disabled = index >= maxStep;
                                    return (
                                        <li
                                            key={link.to}
                                            role="menuitem"
                                            className="c-submenu__item c-submenu__item--separator"
                                        >
                                            {/* There is a specific API in react-router v6 for this */}
                                            {link.reloadDocument ? (
                                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                <a
                                                    href={disabled ? '#' : `${urls.checkout}${link.to}`}
                                                    className={classnames(
                                                        'c-link c-link--quaternary c-link--start c-submenu__link',
                                                        { 'is-inactive': disabled }
                                                    )}
                                                    aria-current={
                                                        matchPath(link.to, { path: location.pathname })
                                                            ? 'page'
                                                            : undefined
                                                    }
                                                >
                                                    <FormattedMessage id={link.label} />
                                                </a>
                                            ) : (
                                                <NavLink
                                                    className={classnames(
                                                        'c-link c-link--quaternary c-link--start c-submenu__link',
                                                        { 'is-inactive': disabled }
                                                    )}
                                                    to={link.to}
                                                    exact
                                                    // @ts-ignore
                                                    disabled={disabled}
                                                >
                                                    <FormattedMessage id={link.label} />
                                                </NavLink>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Breadcrumbs;
