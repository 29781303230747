import classnames from 'classnames';
import React, { ChangeEventHandler, ReactNode } from 'react';

export interface CheckboxProps {
    checked?: boolean;
    label: ReactNode;
    labelClassNames?: string;
    name: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

const Checkbox = React.forwardRef(({ checked, label, labelClassNames, name, onChange }: CheckboxProps, ref) => (
    <>
        <input
            // @ts-ignore
            ref={ref}
            id={name}
            className="u-visually-hidden"
            name={name}
            type="checkbox"
            checked={checked}
            onChange={onChange}
        />
        <label htmlFor={name} className={classnames('c-label', labelClassNames)}>
            {label}
        </label>
    </>
));

export default Checkbox;
