import { useQuery } from 'react-query';
import { useAppContext } from '../../context/AppContext';
import apiClient from '../../utils/api';
import PushProduct from './PushProduct';
import useCurrency from '../../hooks/useCurrency';
import { useIntl } from 'react-intl';

const PushProducts = () => {
    const { brand, site } = useAppContext();
    const currency = useCurrency();
    const intl = useIntl();

    const { data } = useQuery('pushProducts', () =>
        apiClient.getPushProducts(
            {
                site: site.code!,
                locale: intl.locale,
                currency: currency?.code?.toLowerCase(),
                brand,
            },
            { secure: false }
        )
    );

    return (
        <>
            {data?.data &&
                data?.data.map((product, index) => {
                    return <PushProduct key={index} product={product} />;
                })}
        </>
    );
};

export default PushProducts;
