import UserCreditsDropdown from '../UserCredits/UserCreditsDropdown';
import VoucherForm from '../Voucher/VoucherForm';

const DiscountForm = () => (
    <>
        <UserCreditsDropdown />
        <VoucherForm />
    </>
);

export default DiscountForm;
