import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import classnames from 'classnames';
import { ReactNode } from 'react';

interface ToggleProps {
    className?: string;
    children?: ReactNode;
    defaultOpen?: boolean;
    label: ReactNode;
    onToggle?: (open: boolean) => void;
}

const Toggle = ({ children, className, defaultOpen = false, label, onToggle }: ToggleProps) => {
    return (
        <div className={classnames('c-disclosure', className)}>
            <Disclosure defaultOpen={defaultOpen}>
                {({ open }) => (
                    <>
                        <DisclosureButton className="c-disclosure__trigger" onClickCapture={() => onToggle?.(!open)}>
                            {label}
                        </DisclosureButton>
                        <DisclosurePanel className="c-disclosure__panel">{open ? children : null}</DisclosurePanel>
                    </>
                )}
            </Disclosure>
        </div>
    );
};

export default Toggle;
