import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';

import { useAppContext } from '../../context/AppContext';
import { FETCH_ORDER_KEY } from '../../context/OrderContext';
import useMutationWithError from '../../hooks/useMutationWithError';
import { ProductProperty, PushProduct as PushProductType } from '../../types/api';
import useAnalytics from '../../utils/analytics';
import apiClient from '../../utils/api';
import scrollTo from '../../utils/scrollTo';
import Button from '../Buttons/Button';
import Dropdown from '../Dropdown/Dropdown';
import { ChevronIcon } from '../Icons';
import Toggle from '../Toggle/Toggle';

type Props = {
    product: PushProductType;
};

const PushProduct = ({ product }: Props) => {
    const queryClient = useQueryClient();
    const { brand, site, orderId } = useAppContext();
    const [selectedProduct, setSelectedProduct] = useState<ProductProperty>();
    const { onPromotionalCampaignAdded } = useAnalytics();
    const addProduct = useMutationWithError(
        (ean: string) =>
            apiClient.addProductOnOrder(
                orderId!,
                {
                    site: site.code!,
                    brand,
                    last_minute: false,
                },
                { eans: [ean] }
            ),
        {
            onSuccess: data => {
                if (data) {
                    selectedProduct && data && onPromotionalCampaignAdded(data.data, selectedProduct);
                    queryClient.setQueryData(FETCH_ORDER_KEY, data);
                    scrollTo('#order');
                }
            },
        }
    );

    return (
        <div className="c-box c-box--outline u-mt-sm-bis u-mt-md@md u-mt-md@md-plus">
            <div className="c-box__inner c-box__inner--sm">
                <div className="u-mb-sm-bis">
                    <img src={product.imageUrl} alt={product.name} className="u-w-100 u-cover" loading="lazy" />
                </div>
                <Toggle
                    className="c-disclosure--arrow"
                    label={
                        <>
                            <span className="c-disclosure__title">{product.name}</span>
                            <i className="c-icon c-disclosure__icon">
                                <ChevronIcon />
                            </i>
                        </>
                    }
                >
                    <div className="o-grid o-grid--gutter-sm u-mt-sm">
                        <div className="o-grid__col o-grid__col--24">
                            {product.text && <div dangerouslySetInnerHTML={{ __html: product.text }} />}
                        </div>
                        {product.products && (
                            <div className="o-grid__col o-grid__col--24">
                                <div className="u-flex">
                                    <Dropdown
                                        className="u-flex-item-fluid"
                                        items={product.products?.map((product, i) => ({
                                            ...product,
                                            uniqueKey: `${product.name}-${i}`,
                                        }))}
                                        onChange={product => {
                                            setSelectedProduct(product);
                                        }}
                                        renderItem={product =>
                                            `${product.name} ${product.selectedVariant?.name} - ${product.selectedVariant?.displayPrice}`
                                        }
                                        value={selectedProduct}
                                        itemKey="uniqueKey"
                                    />
                                    <Button
                                        type="button"
                                        className="c-btn c-btn--senary c-btn--loader u-ml-xs-bis"
                                        disabled={addProduct.isLoading || !selectedProduct}
                                        onClick={() => {
                                            selectedProduct &&
                                                addProduct.mutate(selectedProduct.selectedVariant?.selectedItem?.ean!);
                                        }}
                                        isLoading={addProduct.isLoading}
                                    >
                                        <FormattedMessage id="actions.add" />
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </Toggle>
            </div>
        </div>
    );
};

export default PushProduct;
