import { createRoot } from 'react-dom/client';
import App from './App';

async function renderApp() {
    const container = document.getElementById('root');
    const root = createRoot(container!);

    root.render(<App />);
}

renderApp();
