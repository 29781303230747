import { FormattedMessage } from 'react-intl';
import { OrderProduct } from '../../types/api';
import AddToWishlist from '../Buttons/AddToWishlist';
import ChangeQuantityFromBasket from '../Buttons/ChangeQuantityFromBasket';
import { CrossIcon } from '../Icons';

type Props = {
    product: OrderProduct;
    onIgnore: () => void;
    removeProduct: () => void;
};

const DeleteOrderItem = ({ product, onIgnore, removeProduct }: Props) => {
    return (
        <div key={product.orderItemProductId} className="c-order-checkout__item-deleted">
            <p className="u-text-uppercase">
                <FormattedMessage id="cart.list.add_to_wishlist" />
            </p>
            <p className="u-mt-xs">
                <ChangeQuantityFromBasket
                    product={product}
                    className="c-btn c-btn--close c-btn--loader u-mr-xs c-btn--senary"
                    quantity={0}
                >
                    <FormattedMessage id="actions.no" />
                </ChangeQuantityFromBasket>
                <AddToWishlist ean={product.productProperty?.selectedVariant?.selectedItem?.ean!} onAdd={removeProduct}>
                    <FormattedMessage id="actions.yes" />
                </AddToWishlist>
            </p>
            <p>
                <button
                    type="button"
                    className="u-btn-unstyled u-text-uppercase"
                    style={{ color: 'var(--color-gray-500)' }}
                    onClick={onIgnore}
                >
                    <FormattedMessage id="cart.list.keep_product" />
                </button>
            </p>
            <button
                type="button"
                className="c-dialog__close u-btn-unstyled"
                data-dialog-hide=""
                style={{ position: 'absolute', top: '2rem', right: '1rem' }}
                onClick={onIgnore}
            >
                <i className="c-icon c-dialog__icon">
                    <CrossIcon />
                </i>
            </button>
        </div>
    );
};

export default DeleteOrderItem;
