import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useAppContext } from '../../context/AppContext';
import { useCountry } from '../../context/CountryContext';
import { FETCH_ORDER_KEY, useOrderContext } from '../../context/OrderContext';
import { OrderProduct } from '../../types/api';
import apiClient from '../../utils/api';
import Button from '../Buttons/Button';
import Modal from './Modal';

type Props = {
    product: OrderProduct;
    onRequestClose: () => void;
};

export type FormValues = {
    message?: string;
};

const EcardMessageModal = ({ product, onRequestClose }: Props) => {
    const { brand, locale, site } = useAppContext();
    const { country } = useCountry();
    const { order } = useOrderContext();
    const queryClient = useQueryClient();

    const { control, handleSubmit, watch } = useForm<FormValues>({
        defaultValues: {
            message: product.ecardProperty?.message || undefined,
        },
    });
    const message = watch('message');

    const {
        mutateAsync: updateEcardMessage,
        isLoading,
        error,
    } = useMutation(
        ['updateEcardMessage'],
        (message?: string) =>
            apiClient.updateEcardMessageFromOrder(
                order?.uuid!,
                product.orderItemProductId!,
                { site: site.code!, brand },
                { message }
            ),
        {
            onSuccess: data => {
                if (data) {
                    queryClient.setQueryData(FETCH_ORDER_KEY, data);
                }
                onRequestClose();
            },
        }
    );

    const onSubmit = ({ message }: FormValues) => updateEcardMessage(message ? message : undefined);

    return (
        <Modal onRequestClose={onRequestClose} isOpen className="c-dialog--lg c-dialog--box-sm@sm">
            <h4 className="u-h4-bis u-text-uppercase">
                <FormattedMessage id="ecard.message_modal.title" />
            </h4>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="c-order-checkout c-order-checkout--options u-mt-sm">
                    <div className="c-order-checkout__body">
                        <div className="c-order-checkout__item">
                            <div className="o-grid o-grid--gutter-sm-bis@sm">
                                <div className="o-grid__col o-grid__col--10 o-grid__col--5@md o-grid__col--5@md-plus">
                                    <div className="c-order-checkout__image">
                                        <img
                                            src={product.imageUrl}
                                            alt={product.label}
                                            width="122"
                                            height="178"
                                            className="u-w-100"
                                        />
                                    </div>
                                </div>
                                <div className="o-grid__col o-grid__col--14 o-grid__col--19@md o-grid__col--19@md-plus">
                                    <p className="c-order-checkout__label">{product.label}</p>
                                    {product.ecardProperty && (
                                        <p className="u-mt-n">
                                            <FormattedMessage id="ecard.message_modal.amount" />{' '}
                                            {product.totalDisplayPrice}
                                            {' - '}
                                            <FormattedMessage id="ecard.message_modal.receiver" />{' '}
                                            {product.ecardProperty.receiverEmail}
                                            {' - '}
                                            <FormattedMessage id="ecard.message_modal.send_date" />{' '}
                                            {new Date(product.ecardProperty.sendAt!).toLocaleString(
                                                `${locale}-${country.countryISO}`,
                                                {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: '2-digit',
                                                }
                                            )}
                                        </p>
                                    )}
                                    <div>
                                        <label htmlFor="message">
                                            <FormattedMessage id="ecard.message_modal.message" />
                                        </label>
                                        <Controller
                                            control={control}
                                            name="message"
                                            render={({ field }) => (
                                                <textarea
                                                    {...field}
                                                    id="message"
                                                    className="c-field c-form-group__field u-mt-xs u-w-100"
                                                    maxLength={1000}
                                                    style={{ minHeight: '89px' }}
                                                />
                                            )}
                                        />
                                        <p className="u-mt-xs u-text-right">
                                            <FormattedMessage
                                                id="ecard.message_modal.message.remaining"
                                                values={{ nb: 1000 - (message?.length || 0) }}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!!error && (
                            <p className="u-mt-n u-text-center u-color-error">
                                <FormattedMessage id="error.server" />
                            </p>
                        )}

                        <div className="u-text-center u-mt-sm">
                            <Button type="submit" className="c-btn c-btn--loader u-w-100" isLoading={isLoading}>
                                <FormattedMessage id="actions.validate" />
                            </Button>
                            <Button
                                type="button"
                                className="c-btn c-btn--secondary u-w-100 u-mt-sm"
                                onClick={onRequestClose}
                                disabled={isLoading}
                            >
                                <FormattedMessage id="actions.cancel" />
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default EcardMessageModal;
