/**
 * Since we can't parse a cloudinary media properly and modify transformation with the JS sdk, we do it manually for the specific use case of reducing image size client side
 */
export function getCroppedImageUrl(url: string, width: number) {
    if (url.includes('w_auto')) {
        return url.replace(/w_auto:\d+/, `w_${width}`);
    } else if (url.includes('w_')) {
        return url.replace(/w_\d+/, `w_${width}`);
    } else if (url.includes('c_scale')) {
        return url.replace('c_scale', `c_scale,w_${width}`);
    } else {
        // If the URL does not contain the "w_" parameter, add it along with the width value
        return url.replace(/upload\//, `upload/c_scale,w_${width}/`);
    }
}
