import { OMS } from '../types';
import { getApiConfig, proxyHandler } from './api';

const apiClient = new OMS.Api(getApiConfig<OMS.ApiConfig>(process.env.REACT_APP_OMS_API_URL, process.env.REACT_APP_API_KEY));

const createApiClientProxy = <ApiType>(client: ApiType): ApiType => {
    return new Proxy(client, proxyHandler);
};

export const omsPublic = createApiClientProxy(apiClient.omsPublic);
export const omsAuthenticated = createApiClientProxy(apiClient.omsAuthenticated);
