import { ReactNode } from 'react';
import Checkbox from '../Form/Checkbox/Checkbox';

interface MultiSelectProps<ItemType> {
    itemKey?: keyof ItemType;
    items: ItemType[];
    name: string;
    onChange: (selectedValues: ItemType[]) => void;
    renderItem: (item: ItemType) => ReactNode;
    selectedValues?: ItemType[];
    selectAllLabel?: JSX.Element;
}

const MultiSelect = <ItemType extends { [index: string]: any }>({
    itemKey = 'id',
    items,
    name,
    onChange,
    renderItem,
    selectedValues = [],
    selectAllLabel,
}: MultiSelectProps<ItemType>) => {
    const toggleItem = (item: any) => (checked: boolean) => {
        if (checked === true) {
            onChange([...selectedValues, item]);
        } else {
            const newSelectedValues = [...selectedValues];
            const itemIndex = selectedValues.findIndex(i => i[itemKey] === item[itemKey]);
            if (itemIndex > -1) {
                newSelectedValues.splice(itemIndex, 1);
                onChange(newSelectedValues);
            }
        }
    };

    return (
        <ul className="u-list-unstyled u-mt-xs-sm u-ml-sm">
            {items.map(item => (
                <li className="u-mt-xxs-bis@md u-mt-xxs-bis@md-plus" key={item[itemKey]}>
                    <Checkbox
                        name={`checkbox-${name}-${item[itemKey]}`}
                        label={renderItem(item)}
                        labelClassNames="c-label--secondary c-label--custom c-label--checkbox u-text-normal"
                        checked={!!selectedValues.find(i => i[itemKey] === item[itemKey])}
                        onChange={e => toggleItem(item)(e.target.checked)}
                    />
                </li>
            ))}
            {selectAllLabel && items.length > 1 && (
                <li className="u-mt-xxs-bis@md u-mt-xxs-bis@md-plus">
                    <Checkbox
                        name={`checkbox-${name}-all`}
                        label={selectAllLabel}
                        labelClassNames="c-label--secondary c-label--custom c-label--checkbox u-text-normal"
                        checked={items.length === selectedValues.length}
                        onChange={e => (e.target.checked ? onChange(items) : onChange([]))}
                    />
                </li>
            )}
        </ul>
    );
};

export default MultiSelect;
