import classNames from 'classnames';
import { FocusEvent, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useAppContext } from '../../../context/AppContext';
import { FETCH_ORDER_KEY } from '../../../context/OrderContext';
import useMutationWithError from '../../../hooks/useMutationWithError';
import { OrderProduct } from '../../../types/api';
import apiClient from '../../../utils/api';
import Button from '../../Buttons/Button';
import Dropdown from '../../Dropdown/Dropdown';
import Modal from './../Modal';
import Preview from './Preview';

type Props = {
    product: OrderProduct;
    onRequestClose: () => void;
};

export type FormValues = {
    position?: string | null;
    color?: string | null;
    rows: string[] | null;
};

const positionsTranslations = defineMessages({
    heart: { id: `customisation.position.heart` },
    right: { id: `customisation.position.right` },
});

const CustomisationModal = ({ product, onRequestClose }: Props) => {
    const { formatMessage } = useIntl();
    const queryClient = useQueryClient();
    const { brand, orderId, site } = useAppContext();
    const rowFocusElement = useRef<{ element: HTMLInputElement; caretPosition: number | null }>();
    const { register, control, handleSubmit, setValue, watch } = useForm<FormValues>({
        defaultValues: {
            position: product.customisation?.position || product.customisation?.service?.positions?.[0],
            color: product.customisation?.color || product.customisation?.service?.colors?.[0].label,
            rows: product.customisation?.rows,
        },
    });
    const translateColorLabel = (colorLabel: string) => {
        return product.customisation?.service?.colors?.find(color => color.label === colorLabel)?.value;
    };
    const values = watch();
    const colorValue = values.color ? translateColorLabel(values.color) : null;

    const updateCustomisation = useMutationWithError(
        (data: FormValues) =>
            apiClient.updateCustomisation(
                orderId,
                product.orderItemProductId!,
                {
                    site: site.code!,
                    brand,
                },
                {
                    rows: data.rows?.filter(row => row.length > 0),
                    color: data.color,
                    position: data.position,
                    type: product.customisation?.service?.type,
                    format: product.customisation?.service?.format,
                }
            ),
        {
            onSuccess: data => {
                if (data) {
                    queryClient.setQueryData(FETCH_ORDER_KEY, data);
                }

                onRequestClose();
            },
        }
    );

    const onSubmit = (data: FormValues) => updateCustomisation.mutate(data);

    const onRemove = () => {
        setValue('rows', product.customisation?.rows?.map(row => '') || []);
        handleSubmit(onSubmit)();
    };

    const onRowBlur = (e: FocusEvent<HTMLInputElement>) => {
        rowFocusElement.current = {
            element: e.target,
            caretPosition: e.target.selectionStart,
        };
    };

    const onAddSymbol = (symbol: string) => {
        if (rowFocusElement.current && rowFocusElement.current.caretPosition !== null) {
            const { element, caretPosition } = rowFocusElement.current;
            const originalText = element.value;

            if (originalText.length < element.maxLength) {
                let begin = originalText.substring(0, caretPosition);
                let end = originalText.substring(caretPosition, originalText.length);
                setValue(element.name as `rows.${number}`, begin + symbol + end);
                rowFocusElement.current.caretPosition += 1;
            }
        }
    };

    const imageUrl = product.customisation?.customisationMediaUrl || product.imageUrl;

    return (
        <Modal
            isOpen={true}
            onRequestClose={onRequestClose}
            className="c-dialog--xxxl c-dialog--box-lg c-dialog--border-none"
            wrapper={false}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="o-grid o-grid--gutter-none u-flex-item-fluid">
                    <div className="o-grid__col o-grid__col--13 o-grid__col--24@sm u-none@sm">
                        <div className="c-preview-customisation">
                            <img src={imageUrl} width="650" height="975" alt={product.label} />
                            {colorValue && (
                                <Preview
                                    {...values}
                                    symbols={product.customisation?.service?.symbols || []}
                                    color={colorValue}
                                />
                            )}
                        </div>
                    </div>

                    <div className="o-grid__col o-grid__col--11 o-grid__col--24@sm">
                        <div className="c-dialog__box">
                            <div className="s-cms-product-customisation">
                                {product.customisation?.service?.description && (
                                    <div
                                        className="s-cms-wysiwyg"
                                        dangerouslySetInnerHTML={{
                                            __html: product.customisation?.service?.description,
                                        }}
                                    />
                                )}
                            </div>

                            <div className="u-none@md u-none@md-plus u-mt-md">
                                <div className="c-preview-customisation">
                                    <img src={imageUrl} width="313" height="470" alt={product.label} />
                                    {colorValue && (
                                        <Preview
                                            {...values}
                                            symbols={product.customisation?.service?.symbols || []}
                                            color={colorValue}
                                        />
                                    )}
                                </div>
                            </div>

                            <ol className="c-list-counter">
                                {product.customisation?.service?.positions &&
                                    product.customisation?.service?.positions?.length > 1 && (
                                        <li className="c-list-counter__item u-mt-lg u-mt-md@sm">
                                            <span className="c-list-counter__label">
                                                <FormattedMessage id="customisation.position.label" />{' '}
                                            </span>
                                            <div className="o-grid u-mt-xs">
                                                <div className="o-grid__col o-grid__col--24@sm o-grid__col--20">
                                                    <div className="c-form-group">
                                                        <Controller
                                                            name="position"
                                                            control={control}
                                                            render={({ field }) => {
                                                                const positions =
                                                                    product.customisation?.service?.positions?.map(
                                                                        position => ({
                                                                            // @ts-ignore
                                                                            label: positionsTranslations[position]
                                                                                ? formatMessage(
                                                                                      // @ts-ignore
                                                                                      positionsTranslations[position]
                                                                                  )
                                                                                : position,
                                                                            position,
                                                                        })
                                                                    ) || [];
                                                                return (
                                                                    <Dropdown
                                                                        itemKey="position"
                                                                        items={positions}
                                                                        onChange={position =>
                                                                            field.onChange(position.position)
                                                                        }
                                                                        value={positions.find(
                                                                            position =>
                                                                                position.position === field.value
                                                                        )}
                                                                        renderItem={item => item.label}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )}

                                <li className="c-list-counter__item u-mt-lg u-mt-md@sm">
                                    <span className="c-list-counter__label">
                                        <FormattedMessage id="customisation.message.title" />{' '}
                                        {product.customisation?.service?.rows?.length === 1 && (
                                            <FormattedMessage
                                                id="customisation.row.limit"
                                                values={{ limit: product.customisation?.service?.rows[0].limit }}
                                            />
                                        )}
                                    </span>

                                    {product.customisation?.service?.rows?.map((row, index) => (
                                        <div
                                            key={index}
                                            className={classNames('c-form-group', {
                                                'u-mt-xs': index === 0,
                                                'u-mt-sm': index > 0,
                                            })}
                                        >
                                            {product.customisation?.service?.rows &&
                                                product.customisation?.service?.rows?.length > 1 && (
                                                    <label
                                                        className="c-label c-label--secondary u-text-normal u-p-n"
                                                        htmlFor="gravure_0"
                                                    >
                                                        <FormattedMessage
                                                            id="customisation.row.line"
                                                            values={{ line: index + 1 }}
                                                        />{' '}
                                                        <span className="u-text-lowercase">
                                                            <FormattedMessage
                                                                id="customisation.row.limit"
                                                                values={{ limit: row.limit }}
                                                            />
                                                        </span>
                                                    </label>
                                                )}
                                            <input
                                                type="text"
                                                className="c-field c-form-group__field js-product-customization-input"
                                                placeholder={formatMessage({ id: 'customisation.placeholder' })}
                                                maxLength={row.limit || undefined}
                                                pattern={`[${product.customisation?.service?.authorizedCharactersPattern}]+`}
                                                {...register(`rows.${index}` as any)}
                                                onBlur={onRowBlur}
                                            />
                                            <p className="c-form-group__validation-message">
                                                <FormattedMessage id="customisation.errors.validation" />
                                            </p>
                                        </div>
                                    ))}

                                    {product.customisation?.service?.symbols &&
                                        product.customisation?.service?.symbols?.length > 0 && (
                                            <div className="c-form-group u-mt-sm">
                                                <label className="c-label c-label--secondary u-text-normal u-p-n">
                                                    <FormattedMessage id="customisation.symbols.label" />
                                                </label>
                                                <ul className="c-product-symbols">
                                                    {product.customisation?.service?.symbols.map(symbol => (
                                                        <li className="c-product-symbols__item" key={symbol}>
                                                            <label
                                                                className="c-product-symbols__label"
                                                                tabIndex={0}
                                                                onClick={() => onAddSymbol(symbol)}
                                                            >
                                                                {symbol}
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                </li>

                                {product.customisation?.service?.colors &&
                                    product.customisation?.service?.colors?.length > 0 && (
                                        <li className="c-list-counter__item u-mt-lg u-mt-md@sm">
                                            <span className="c-list-counter__label">
                                                {' '}
                                                <FormattedMessage id="customisation.color.title" />
                                            </span>

                                            <ul className="c-product-color js-customisation-color">
                                                {product.customisation?.service?.colors.map((color, index) => {
                                                    return (
                                                        <li className="c-product-color__item" key={color.value}>
                                                            <input
                                                                id={`customisation-color-${index}`}
                                                                className="u-visually-hidden"
                                                                type="radio"
                                                                value={color.label!}
                                                                {...register('color')}
                                                            />
                                                            <label
                                                                className="c-product-color__label"
                                                                style={
                                                                    {
                                                                        '--product-color-label-background-color':
                                                                            color.value,
                                                                    } as React.CSSProperties
                                                                }
                                                                tabIndex={0}
                                                                htmlFor={`customisation-color-${index}`}
                                                            >
                                                                <span className="u-visually-hidden">{color.value}</span>
                                                            </label>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </li>
                                    )}
                            </ol>

                            <div className="u-mt-lg ">
                                <Button
                                    type="submit"
                                    className="c-btn u-w-100"
                                    isLoading={updateCustomisation.isLoading}
                                >
                                    <FormattedMessage id="customisation.actions.edit" />
                                </Button>
                                <Button
                                    type="button"
                                    className="c-btn c-btn--secondary u-w-100 u-mt-sm-bis"
                                    onClick={onRemove}
                                    isLoading={updateCustomisation.isLoading}
                                >
                                    <FormattedMessage id="customisation.actions.delete" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default CustomisationModal;
