import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, useIntl } from 'react-intl';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import ErrorBoundary from './components/Errors/ErrorBoundary';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Loader from './components/Loader/Loader';
import OrderTimerModal from './components/Modal/OrderTimer';
import Popins from './components/Popins/Popins';
import { useAppContext } from './context/AppContext';
import { useCountry } from './context/CountryContext';
import { ErrorProvider } from './context/ErrorContext';
import { useOrderContext } from './context/OrderContext';
import { STEP } from './context/WorkflowContext';
import { useUrl } from './hooks/useUrl';
import IndexRoute from './routes/Index';
import { BRANDS, getBrand } from './utils/brand';
import HistoryListener from './utils/HistoryListener';
import WorkflowRoute from './utils/WorkflowRoute';

const descriptions = defineMessages({
    [BRANDS.sezane]: { id: `meta.description.sezane` },
    [BRANDS.octobre]: { id: `meta.description.octobre` },
});

const ShippingRoute = lazy(() => import('./routes/Shipping'));
const PaymentRoute = lazy(() => import('./routes/Payment'));
const PaymentSuccessRoute = lazy(() => import('./routes/PaymentSuccess'));
const GlobalERoute = lazy(() => import('./routes/GlobalE'));
const DebugRoute = lazy(() => import('./routes/DebugRoute'));

const MainContainer = () => {
    const { urls, routes } = useUrl();
    const brand = getBrand();
    const intl = useIntl();
    const { isGlobalE, locale, site } = useAppContext();
    const { country } = useCountry();
    const { order } = useOrderContext();

    return (
        <Router basename={urls.checkout}>
            <ErrorProvider>
                <Helmet
                    htmlAttributes={{
                        lang: country
                            ? `${locale}-${country.countryISO}`
                            : `${site.defaultLocale}-${site.defaultCountryCode}`,
                    }}
                >
                    <meta name="description" content={intl.formatMessage({ id: descriptions[brand].id })} />
                </Helmet>
                <HistoryListener />
                <div id="popinBannerContainer"></div>
                <ErrorBoundary>
                    <Header brand={brand} />
                    <Breadcrumbs />
                </ErrorBoundary>
                <main className="c-page__main c-page__main--checkout">
                    <Suspense fallback={<Loader />}>
                        {!isGlobalE ? (
                            <Switch>
                                <WorkflowRoute path={routes.shipping} step={STEP.SHIPPING} exact>
                                    <ShippingRoute />
                                </WorkflowRoute>
                                <WorkflowRoute path={routes.payment} step={STEP.PAYMENT} exact>
                                    <PaymentRoute />
                                </WorkflowRoute>
                                <Route path={routes.paymentSuccess} component={PaymentSuccessRoute} exact />
                                <Route path={routes.cart} component={IndexRoute} exact />
                                {process.env.REACT_APP_DEBUG_ROUTE === 'true' && (
                                    <Route path="/debug" component={DebugRoute} exact />
                                )}
                                <Redirect to={routes.cart} />
                            </Switch>
                        ) : (
                            <Switch>
                                <Route path={routes.globalE} component={GlobalERoute} exact />
                                <Route path={routes.cart} component={IndexRoute} exact />
                                <Redirect to={routes.cart} />
                            </Switch>
                        )}
                    </Suspense>
                </main>
                <ErrorBoundary>
                    <Footer />
                </ErrorBoundary>
                <ErrorBoundary>{order && <OrderTimerModal expireAt={order.expireAt!} />}</ErrorBoundary>
                <ErrorBoundary>
                    <Popins />
                </ErrorBoundary>
            </ErrorProvider>
        </Router>
    );
};

export default MainContainer;
