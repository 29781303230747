import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { User } from '../types/api';
import apiClient from '../utils/api';
import { AuthData, getAuth } from '../utils/auth';
import { useAppContext } from './AppContext';

export type AuthContextType = {
    authenticated: boolean;
    keycloakId: string;
    user?: User;
    isAuthenticating: boolean;
};

type AuthProviderProps = {
    children: ReactNode;
    initialAuthData?: {
        auth: AuthData;
        user: User;
    };
};

const AuthContext = React.createContext<AuthContextType>({
    authenticated: false,
    keycloakId: '',
    isAuthenticating: false,
});

const USER_KEY = 'user';

export const AuthProvider = ({ children, initialAuthData }: AuthProviderProps) => {
    const { brand, site } = useAppContext();
    const [auth, setAuth] = useState<AuthData | undefined>(initialAuthData?.auth);
    const [isAuthenticating, setIsAuthenticating] = useState<boolean>(process.env.NODE_ENV !== 'test');

    useEffect(() => {
        (async () => {
            try {
                const authData = await getAuth(true);

                if (authData) {
                    setAuth(authData);
                } else {
                    setIsAuthenticating(false);
                }
            } catch (e) {
                setIsAuthenticating(false);
            }
        })();
    }, []);

    const keycloakId = auth?.parsedToken.sub || '';
    const authenticated = !!auth?.accessToken;

    const { data } = useQuery(USER_KEY, () => apiClient.getUser(keycloakId, { brand, site: site.code! }), {
        enabled: !!(keycloakId && auth?.parsedToken && site.code),
        onSettled: () => {
            if (process.env.NODE_ENV !== 'test') {
                // Prevent rerender of context in test
                setIsAuthenticating(false);
            }
        },
    });

    return (
        <AuthContext.Provider
            value={{
                authenticated,
                keycloakId,
                user: initialAuthData ? initialAuthData?.user : data?.data,
                isAuthenticating,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const authContext = useContext(AuthContext);

    return authContext;
};
