const ChevronIcon = () => (
    <svg
        width="36"
        height="22"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 11.2 6.8"
        className="c-icon__svg"
        aria-hidden="true"
    >
        <path d="M5.6 4.4L10 0l1.2 1.2-5.6 5.6L0 1.2 1.2 0l4.4 4.4z" />
    </svg>
);

export default ChevronIcon;
