import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { FETCH_ORDER_KEY } from '../../context/OrderContext';
import { useUrl } from '../../hooks/useUrl';
import { resetOrderId } from '../../utils/order';
import Modal from './Modal';

type OrderTimerModalProps = {
    expireAt: string;
};

// Guard to avoid displaying the modal multiple time for the same order
const hasDisplayedForDate: Record<string, boolean> = {};

const OrderTimerModal = ({ expireAt }: OrderTimerModalProps) => {
    const cartTimeout = new Date(expireAt).getTime();
    const [isOpen, setIsOpen] = useState(false);
    const { replace } = useHistory();
    const queryClient = useQueryClient();
    const { routes } = useUrl();

    useEffect(() => {
        const timer = setInterval(() => {
            const msRemaining = cartTimeout - Date.now();
            if (msRemaining < 0 && !hasDisplayedForDate[expireAt]) {
                setIsOpen(true);
                hasDisplayedForDate[expireAt] = true;
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [cartTimeout, expireAt]);

    const onRequestClose = useCallback(() => {
        setIsOpen(false);
        replace(routes.cart);
        queryClient.setQueryData(FETCH_ORDER_KEY, null);
        resetOrderId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [replace]);

    useEffect(() => {
        if (isOpen) {
            const timeoutID = setTimeout(() => {
                onRequestClose();
            }, 5000);

            return () => clearTimeout(timeoutID);
        }
    }, [isOpen, onRequestClose, queryClient]);

    return (
        <Modal onRequestClose={onRequestClose} isOpen={isOpen} className="c-dialog--sm">
            <h4 className="u-h4-bis u-text-uppercase u-text-center">
                <FormattedMessage id="cart.expired" />
            </h4>
        </Modal>
    );
};

export default OrderTimerModal;
